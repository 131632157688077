import { ICommonRequestFiltersParams } from 'arcadia-common-fe';
import { MachineId } from '../../types/machine';
import { GameId } from '../../types/gameId';
import { IEnvironment } from '../../types/environment';

export enum MachineStatus {
  ready = 'ready',
  inPlay = 'in-play',
  seeding = 'seeding',
  preparing = 'preparing',
  shuttingDown = 'shutting-down',
  stopped = 'stopped',
  offline = 'offline',
  onHold = 'onHold',
  error = 'error',
}

export enum QueueStatus {
  inPlay = 'in-play',
  ready = 'ready',
  drying = 'drying',
  stopped = 'stopped',
}

export enum MachineAction {
  dry,
  shutdown,
  remove,
  reboot,
}

export enum MachineLogSource {
  player = 'player',
  robot = 'robot',
  game = 'game',
}

export interface IMachine {
  id: MachineId
  environment: IEnvironment
  status: MachineStatus
  queueStatus: QueueStatus
  name: string
  version: string
  workingHours: number | null
  groupId: number
  groupName: string
  siteName: string
  viewers: number
  inQueue: number
  uptime: number | null
  serial: string
  cameraIds: string[]
  controllerIP: string | null
  location: string
  lastDiagnosticDate: string | null
  gameId: GameId
  startDate: string
  endDate: string
  addingBallToken: string | null
  configuration: {
    brand: string
    model: string
    dispensers: {}
    video_crop_margins: {
      top: number
      bottom: number
      left: number
      right: number
    }
  },
  chipsOnTable: {}
}

export type IMachines = IMachine[];

export interface IMachinesFiltersPanelValues {
  id?: MachineId
  status?: MachineStatus[]
  name?: string | string[]
  groupName?: string | string[]
  siteName?: string | string[]
  serial?: string
  environvent?: IEnvironment
  viewers?: number
  inQueue?: number
  uptime?: number
  gameId?: string
}

export interface IGetMachinesRequestFiltersParams extends ICommonRequestFiltersParams, IMachinesFiltersPanelValues {
}

export interface IGetMachinesResponseBody {
  total: number
  machines: IMachines
}

export interface IMachineActionRequestBody {
  id: MachineId
}

export interface IActivateRequestBody {
  id: MachineId
  resetTableState: boolean
  resetDispensers: boolean
}

export interface IMachineReassignRequestBody {
  id: MachineId
  groupId: number
}

export interface IMachineRebootRequestBody {
  id: MachineId
}

interface IDispenser {
  id: number
  name: string
  level: number
  alertThreshold: number
  capacity: number
  chipType: string
}

type IDispensers = IDispenser[];

export interface IGetMachineDispensersRequestBody {
  id: MachineId
}

export interface IGetMachineDispensersResponseBody {
  dispensers: IDispensers
}

export interface IMachineDialogActionState {
  id: MachineId | null
  action: MachineAction
  isOpen: boolean
  isLoading?: boolean
}

export interface IMachineDialogActivateState {
  id: MachineId | null
  isOpen: boolean
  isLoading?: boolean
  gameId: GameId | null
}

export interface IMachineDialogReassignState {
  id: MachineId | null
  isOpen: boolean
  isLoading: boolean
}

export interface IMachinesSlice {
  machinesReducer: IMachinesReducer
}

export interface IPostMachineRequestBody extends IMachine {
  password: string
}

export interface IPutMachineRequestBody extends IMachine {
  password: string
}

export interface IMachineDialogFormState {
  isOpen: boolean
  isLoading?: boolean
  initialValues?: IMachine
}

export interface IMachinesReducer extends IGetMachinesResponseBody {
  readonly isLoading: boolean
  readonly isExporting: boolean
  readonly dialogForm: IMachineDialogFormState
  readonly dialogAction: IMachineDialogActionState
  readonly dialogActivate: IMachineDialogActivateState
  readonly dialogReassign: IMachineDialogReassignState
  readonly dialogLogs: IMachineLogsDialog
  readonly dialogComments: IMachineCommentsDialog
}

export interface IMachineLogItem {
  createdDate: string
  source: MachineLogSource
  type: string
  parameters: object
}

export interface IMachineCommentItem {
  createdAt: string
  comment: string
  username: string
  id: number
}

export type IMachineLogs = IMachineLogItem[];

export type IMachineComments = IMachineCommentItem[];

export interface IMachineCommentsFiltersPanelValues {
  dateFrom?: string
  dateTo?: string
  type?: string
  source?: string
}

export interface IMachineLogsFiltersPanelValues {
  dateFrom?: string
  dateTo?: string
  type?: string
  source?: string
}

export interface IGetLogsRequestFiltersParams extends ICommonRequestFiltersParams, IMachineLogsFiltersPanelValues {
}

export interface IGetCommentsRequestFiltersParams extends ICommonRequestFiltersParams, IMachineCommentsFiltersPanelValues {
}

export interface IMachineLogsDialog {
  machine: null | IMachine
  isOpen: boolean
  isLoading: boolean
  isExporting: boolean
  total: number
  logs: IMachineLogs
  filterParams: IGetLogsRequestFiltersParams
}

export interface IMachineCommentsDialog {
  machine: null | IMachine
  isOpen: boolean
  isLoading: boolean
  isExporting: boolean
  total: number
  comments: IMachineComments
  filterParams: IGetCommentsRequestFiltersParams
}

export interface IGetMachineLogsRequestBody {
  machine: IMachine
  filterParams: IGetLogsRequestFiltersParams
}

export interface IGetMachineCommentsRequestBody {
  machine: null | IMachine
  filterParams: IGetCommentsRequestFiltersParams
}

export interface IAddMachineCommentRequestBody{
  machine: null | IMachine
  filterParams: IGetCommentsRequestFiltersParams
  comment: string
}

export interface IGetMachineLogsResponseBody {
  total: number
  logs: IMachineLogs
}

export interface IGetMachineCommentsResponseBody {
  total: number
  records: IMachineComments
}

export interface IAddMachineCommentResponseBody {
  createdAt: string
  comment: string
  username: string
  id: number
}

export interface IAddMachineCommentsSuccessPayload {
  machine: null | IMachine;
  filterParams: any;
  commentResponse: IAddMachineCommentResponseBody;
}

export interface IExportMachineCommentsRequestBody {
  machine: IMachine
  filterParams: IGetLogsRequestFiltersParams
}

export interface IExportMachineLogsRequestBody {
  machine: IMachine
  filterParams: IGetLogsRequestFiltersParams
}
