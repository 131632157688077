import React, { useCallback, useMemo } from 'react';
import { AnimatePresence, motion, Variant } from 'framer-motion';
import { IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import { useStylesWithDeviceInfo } from '../../hooks/useStylesWithDeviceInfo';
import {
  IOverlayStyleProps, IOverlayStyles, getStyles,
} from './styles/Overlay.styles';

export * from './styles/Overlay.styles';

export type ColorUnion = 'primary' | 'secondary';

export { OverlayBackdropColor } from './styles/Overlay.styles';

export const OverlayColor : { [key in ColorUnion] : ColorUnion } = {
  primary: 'primary',
  secondary: 'secondary',
};

export enum AnimationKeys {
  visible = 'visible',
  hidden = 'hidden',
}

export enum OverlayAppearanceType {
  instant = 'instant'
}

export enum OverlayDisappearanceType {
  delayed = 'delayed'
}

const appearanceAnimationByType: { [key in OverlayAppearanceType]: Variant} = {
  [OverlayAppearanceType.instant]: {
    transition: {
      duration: 0,
    },
  },
};

const disappearanceAnimationByType: { [key in OverlayDisappearanceType]: Variant} = {
  [OverlayDisappearanceType.delayed]: {
    transition: {
      delay: 0,
    },
  },
};

export interface IOverlayProps extends Partial<IOverlayStyleProps> {
  styles?: IStyleFunctionOrObject<IOverlayStyleProps, IOverlayStyles>;
  color?: ColorUnion;
  isVisible?: boolean;
  isScatter?: boolean;
  isLobby?: boolean;
  appearanceType?: OverlayAppearanceType;
  disappearanceType?: OverlayDisappearanceType;
  visibleAnimationVariant?: Variant;
  hiddenAnimationVariant?: Variant;
  onClick?: () => void;
  e2eSelector?: string;
  disableClickOnOverlay?: boolean;

}

const OverlayBase: React.FC<IOverlayProps> = ({
  styles,
  className,
  classNameContent,
  classNameOverlayBackDrop,
  isVisible,
  isScatter,
  isLobby,
  disableClickOnOverlay,
  overlayBackdropColor,
  children,
  appearanceType,
  disappearanceType,
  visibleAnimationVariant,
  hiddenAnimationVariant,
  onClick,
  e2eSelector,
}) => {
  const animationVariants = useMemo((): { [key in AnimationKeys]: Variant } => ({
    [AnimationKeys.visible]: {
      opacity: 1,
      ...(appearanceType && appearanceAnimationByType[appearanceType]),
      ...visibleAnimationVariant,
    },
    [AnimationKeys.hidden]: {
      opacity: 0,
      ...(disappearanceType && disappearanceAnimationByType[disappearanceType]),
      ...hiddenAnimationVariant,
    },
  }), [visibleAnimationVariant, hiddenAnimationVariant, appearanceType, disappearanceType]);

  const handleOverlayClick = useCallback(() => {
    if (!disableClickOnOverlay && onClick) {
      onClick();
    }
  }, [disableClickOnOverlay, onClick]);

  const classNames = useStylesWithDeviceInfo(styles, {
    className,
    classNameContent,
    overlayBackdropColor,
    classNameOverlayBackDrop,
    isScatter,
    isLobby,
  });

  return (
    <AnimatePresence>
      { isVisible && (
        <motion.div
          className={classNames.root}
          variants={animationVariants}
          initial={AnimationKeys.hidden}
          animate={AnimationKeys.visible}
          exit={AnimationKeys.hidden}
          data-e2e-selector={e2eSelector}
        >
          <div
            className={classNames.overlayBackdrop}
            role="presentation"
            onClick={handleOverlayClick}
          />
          { children && (
            <div className={classNames.overlayContent}>
              { children }
            </div>
          ) }
        </motion.div>
      ) }
    </AnimatePresence>
  );
};

export const Overlay = styled<IOverlayProps, IOverlayStyleProps, IOverlayStyles>(
  OverlayBase,
  getStyles,
);
