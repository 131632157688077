import { createAction } from '@reduxjs/toolkit';
import {
  IGetTransactionsRequestFilterParams,
  ITransactionsNormalizedData,
  TransactionId,
  ITransactionsDismissDialogState,
  ITransactionsRetryDialogState,
  ITransactionsResendDWHDataRequestBody,
} from '../types';

export const getTransactions = createAction<IGetTransactionsRequestFilterParams>('TRANSACTIONS/GET_TRANSACTIONS');
export const getTransactionsSuccess = createAction<ITransactionsNormalizedData>('TRANSACTIONS/GET_TRANSACTIONS/SUCCESS');
export const getTransactionsError = createAction('TRANSACTIONS/GET_TRANSACTIONS/ERROR');

export const setSelectedTransactions = createAction<TransactionId[]>('TRANSACTIONS/SET_SELECTED_TRANSACTIONS');

export const setDismissDialog = createAction<Partial<ITransactionsDismissDialogState> | undefined>('TRANSACTIONS/SET_DISMISS_DIALOG');
export const mergeDismissDialog = createAction<Partial<ITransactionsDismissDialogState> | undefined>('TRANSACTIONS/MERGE_DISMISS_DIALOG');
export const dismissTransactions = createAction<TransactionId[]>('TRANSACTIONS/DISMISS_TRANSACTIONS');

export const setRetryDialog = createAction<Partial<ITransactionsRetryDialogState> | undefined>('TRANSACTIONS/SET_RETRY_DIALOG');
export const mergeRetryDialog = createAction<Partial<ITransactionsRetryDialogState> | undefined>('TRANSACTIONS/MERGE_RETRY_DIALOG');
export const retryTransactions = createAction<TransactionId[]>('TRANSACTIONS/RETRY_TRANSACTIONS');

export const resendDWHDataTransactions = createAction<ITransactionsResendDWHDataRequestBody>('TRANSACTIONS/RESEND_DWH_DATA_TRANSACTIONS');
