import {
  all, call, put, takeLatest, takeEvery,
} from 'redux-saga/effects';
import { normalize, schema } from 'normalizr';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  ITransactions,
  ITransactionsNormalizedData,
  IGetTransactionsRequestFilterParams,
} from '../types';
import {
  getTransactionsRequest,
  dismissTransactionsRequest,
  retryTransactionsRequest,
  resendDWHDataTransactionsRequest,
} from '../api';
import {
  getTransactions,
  getTransactionsError,
  getTransactionsSuccess,
  setSelectedTransactions,
  dismissTransactions,
  mergeDismissDialog,
  setDismissDialog,
  retryTransactions,
  mergeRetryDialog,
  setRetryDialog,
  resendDWHDataTransactions,
} from './actions';

const transactionsSchema = new schema.Entity('transactions');
const transactionsListSchema = new schema.Array(transactionsSchema);

function* handleGetTransactionsRequest(requestParams: IGetTransactionsRequestFilterParams) {
  const { data } = yield call(getTransactionsRequest, requestParams);
  const { result: transactionsIds, entities } = normalize<ITransactions>(data.transactions, transactionsListSchema);

  return {
    ids: transactionsIds,
    entities: entities.transactions,
    total: data.total,
  };
}

export function* handleGetTransactions({ payload }: ReturnType<typeof getTransactions>) {
  try {
    const transactionsData: ITransactionsNormalizedData = yield handleGetTransactionsRequest(payload);

    yield put(getTransactionsSuccess(transactionsData));
  } catch (error: any) {
    yield handleError(error);
    yield put(getTransactionsError());
  }
}

function* handleRefreshTransactions() {
  yield put(getTransactions(queryString.parse(window.location.search)));
}

function* handleDismissTransactions({ payload }: ReturnType<typeof dismissTransactions>) {
  try {
    yield put(mergeDismissDialog({ isLoading: true }));
    yield call(dismissTransactionsRequest, { ids: payload });
    yield put(setDismissDialog());
    yield put(setSelectedTransactions([]));
    yield call(
      toast.success,
      `The Rebate${payload.length > 1 ? 's' : ''} with id -
      ${payload.join(', ')} ${payload.length > 1 ? 'have' : 'has'} been successfully dismissed`,
    );
    yield handleRefreshTransactions();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeDismissDialog({ isLoading: false }));
  }
}

function* handleResendDWHDataTransactions({ payload }: ReturnType<typeof resendDWHDataTransactions>) {
  try {
    yield call(resendDWHDataTransactionsRequest, payload);
    yield call(toast.success, 'The Round ID was sent successfully');
    yield handleRefreshTransactions();
  } catch (error: any) {
    yield handleError(error);
  }
}

function* handleRetryTransactions({ payload }: ReturnType<typeof retryTransactions>) {
  try {
    yield put(mergeRetryDialog({ isLoading: true }));
    yield call(retryTransactionsRequest, { ids: payload });
    yield put(setRetryDialog());
    yield put(setSelectedTransactions([]));
    yield call(
      toast.success,
      `The Rebate${payload.length > 1 ? 's' : ''} with id -
      ${payload.join(', ')} ${payload.length > 1 ? 'have' : 'has'} been successfully retried`,
    );
    yield handleRefreshTransactions();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeRetryDialog({ isLoading: false }));
  }
}

export function* transactionsSagas(): any {
  yield all([
    yield takeLatest(
      getTransactions,
      handleGetTransactions,
    ),
    yield takeEvery(
      dismissTransactions,
      handleDismissTransactions,
    ),
    yield takeEvery(
      retryTransactions,
      handleRetryTransactions,
    ),
    yield takeEvery(
      resendDWHDataTransactions,
      handleResendDWHDataTransactions,
    ),
  ]);
}
