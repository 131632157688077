/* eslint-disable no-console */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Browser } from '../../services/browser';
import { usePrevious } from '../../hooks';
import {
  CSRTCVideoFailedEventReason,
  CSRTCVideoPlayerSessionStatus,
  CSRTCVideoPlayerStatus,
  IIceServersByEnvironment,
  IVideoFailedEvent,
  MediaProvider,
  VideoStreamQuality,
  VideoStreamStatus,
} from './types';
import { Environment } from '../../environment';
import { VideoProtocol } from '../../types';

const CSRTC_STATS_TRACKING_INTERVAL_MS = 1000;
const CSRTC_STATS_DELAY_FREQUENCY_LIMIT = 5;

const ENABLE_DIRECT_TCP_TRANSPORT = true;
const ENABLE_PERIODICAL_DISCONNECT = false;
const PERIODICAL_DISCONNECT_PERIOD = 20;
let i = 0;
let shouldTryTunnel = false;
let previousTryTunnel = false;


const defaultInitOptions = {
  receiverLocation: './js/WSReceiver2.js',
  decoderLocation: './js/video-worker2.js',
  preferredMediaProviders: [MediaProvider.WebRTC],
};

interface IVideoStream {
  setVolume: (volume: number) => void;
  play: () => void;
  stop: () => void;
  id: () => string;
  getStats: (callback: (stats: any) => any) => void;
  on: (status: CSRTCVideoPlayerStatus, callback: () => void) => void;
}

interface ICSRTCVideoPlayerProps {
  className?: string;
  volume: number;
  src: string;
  rtsp: string;
  preloaderUrl: string;
  initOptions?: object;
  sessionOptions?: object;
  webrtcVideoTunneling?: boolean;
  videoStreamStatus: VideoStreamStatus;
  iceServersByEnvironment: IIceServersByEnvironment;
  quality?: VideoStreamQuality;
  videoMonitoringEventSending?: (data: any) => void;
  onSessionStatusChange: (status: CSRTCVideoPlayerSessionStatus) => void;
  onStreamStatusChange: (status: CSRTCVideoPlayerStatus) => void;
  sendVideoFailedEvent?: (data: IVideoFailedEvent) => void;
  handleStableConnection?: (protocol: VideoProtocol) => void;
}

export const CSRTCVideoPlayer: React.FC<ICSRTCVideoPlayerProps> = React.memo(({
  className,
  src,
  rtsp,
  volume,
  initOptions,
  sessionOptions,
  webrtcVideoTunneling,
  preloaderUrl,
  videoStreamStatus = VideoStreamStatus.loading,
  iceServersByEnvironment,
  quality,
  videoMonitoringEventSending,
  onSessionStatusChange,
  onStreamStatusChange,
  handleStableConnection,
  sendVideoFailedEvent = () => {},
}) => {
  const videoContainerRef = useRef<HTMLDivElement | null>(null);
  const bufferingStartRef = useRef(null as number | null);

  const sessionIdRef = useRef(new String());
// const prevSessionIdRef = usePrevious(sessionIdRef);
// const streamRef = useRef<IVideoStream[]>();
  const previousURL = usePrevious(src);
  const previousStreamID = usePrevious(rtsp);
  const previousTryTunnel = shouldTryTunnel;
  const streamsRefMap = useRef(new Map());
  const videoStatsCheckIntervalRef = useRef(new Map());
  const videoStatsDelayTickRef = useRef(new Map());
  const videoTesterIntervalRef = useRef(new Map());
  const startTimeRef = useRef(Date.now());
  const [shouldOptionRebuild, setShouldOptionRebuild] = useState(false);

  let transport: VideoProtocol = VideoProtocol.UDP;
  let useTunnel = shouldTryTunnel;
  let destroy = false;

  const streamName = useRef(rtsp);

  webrtcVideoTunneling = true;

  const defaultSessionOptions = useMemo(() => ({
      urlServer: src,
      mediaOptions: {
        // TODO: Make NODE_ENV: Environment to avoid "NODE_ENV as Environment",
        //      iceServers: ( webrtcVideoTunneling ? iceServersByEnvironment[process.env.NODE_ENV as Environment] : []),
        iceServers: iceServersByEnvironment[process.env.NODE_ENV as Environment],
        ...(shouldTryTunnel ? { iceTransportPolicy: 'relay' } : {}),
      },
      ...sessionOptions
  }), [
    src,
    sessionOptions,
    shouldOptionRebuild
  ]);

  console.log('webrtcVideoTunneling', webrtcVideoTunneling);
  console.log('shouldTryTunnel', shouldTryTunnel);
  console.log('webrtc-src', src);
  console.log('webrtc-opt', JSON.stringify(defaultSessionOptions));
  console.log('webrtc-rtsp', streamName.current);

  const setWebkitEventHandlers = useCallback((video: any) => {
    let needRestart = false;
    let isFullscreen = false;

    video.addEventListener('webkitbeginfullscreen', () => {
      isFullscreen = true;
    });
    video.addEventListener('pause', () => {
      if (needRestart) {
        console.log('Video paused after fullscreen, continue...');
        video.play();
        needRestart = false;
      }
    });
    video.addEventListener('webkitendfullscreen', () => {
      video.play();
      needRestart = true;
      isFullscreen = false;
    });
  }, []);

  const cleanSessions = useCallback(() => {
    const sessions = window?.CSRTCPlayer.getSessions();

    console.log('WEBRTC-SESSION, Found sessions : ', sessions.length);
    sessions?.forEach((n: any) => {
      console.log('DISCONNECT SESSION !!!!!!', n?.id());
      n?.disconnect();
    });
  }, []);

  const getActiveSession = useCallback(() => {
    const sessions = window?.CSRTCPlayer.getSessions();

    console.log('Found sessions : ', sessions.length);

    const session = sessions.find((item: any) => item.id() === sessionIdRef.current);

    console.log(`Found session with req ID : ${sessionIdRef.current}`, session !== undefined);

    return session || null;
  }, []);

  const processTunnelOptions = useCallback((useTunnel: Boolean) => {
    if(!useTunnel) {
      shouldTryTunnel = true;
      setShouldOptionRebuild(true);
    }
  }, []);

  const processSessionError = useCallback((session: any, videoInboundHeight: any = undefined, status: any = undefined) => {
    if (destroy) {
      console.log(`Finish session : ${session?.id()}`);

      return;
    }

    if (transport === VideoProtocol.UDP && ENABLE_DIRECT_TCP_TRANSPORT) {
      transport = VideoProtocol.TCP;

      if (session?.status() === CSRTCVideoPlayerSessionStatus.DISCONNECTED) {
        handleCreateSession();
      }
      handlePlayStream(session);

      return;
    }

    if (webrtcVideoTunneling) {
      if (!useTunnel) {
        processTunnelOptions(shouldTryTunnel);
        transport = VideoProtocol.TCP;
        useTunnel = true;
        console.log('Turn ON STUN tunnel...');
        handleCreateSession();
        return;
      }
    }

    if (status) {
      handlePlayStream(session);
    } else {
      if (!webrtcVideoTunneling) {
        sendVideoFailedEvent({ reason: CSRTCVideoFailedEventReason.disabledTunneling });
        console.log('VIDEO FAILED EVENT', `reason: ${CSRTCVideoFailedEventReason.disabledTunneling}; videoInbounds: ${videoInboundHeight}`);
      }

      if (webrtcVideoTunneling && shouldTryTunnel) {
        sendVideoFailedEvent({ reason: CSRTCVideoFailedEventReason.videoNotAvailableWithTunneling });
        console.log('VIDEO FAILED EVENT', `reason: ${CSRTCVideoFailedEventReason.videoNotAvailableWithTunneling}; videoInbounds: ${videoInboundHeight}`);
      }
    }
  }, [onStreamStatusChange, webrtcVideoTunneling, shouldTryTunnel]);

  const handlePlayStream = useCallback((session: any) => {
    try {
      const options = {
        name: streamName.current,
        display: videoContainerRef.current,
        transport,
        playsInline: true,
        unmutePlayOnStart: true,
        constraints: {},
      };

      console.log('WEBRTC-SESSION', 'STREAM CREATE START !!!');
      console.log('WEBRTC-SESSION STREAM NAME', streamName.current);
      console.log('WEBRTC-SESSION Transport = ', transport);
      console.log('WEBRTC-SESSION STUN', useTunnel);
      console.log('WEBRTC-SESSION QUALITY', quality);

      const stream = session.createStream(options)
        .on(CSRTCVideoPlayerStatus.PLAYING, () => {
          stream.setVolume(volume);
          onStreamStatusChange(CSRTCVideoPlayerStatus.PLAYING);
        });

      streamsRefMap.current.set(streamName.current, stream);

      console.log(`WEBRTC-SESSION (Stream ID = ${stream.id()}) `, 'STREAM CREATE DONE !!!');

      videoStatsDelayTickRef.current.set(stream.id(), 0);

      stream.play();
      stream.setVolume(volume);

      clearInterval(videoStatsCheckIntervalRef.current.get(stream.id()));

      videoStatsCheckIntervalRef.current.set(stream.id(), window.setInterval(() => {
        stream.getStats((stats: any) => {
          const videoInboundHeight: number = stats.inboundStream.video?.height;
          const videoInboundWidth: number = stats.inboundStream.video?.width;

          options.constraints = {
            video: {
              width: videoInboundWidth,
              height: videoInboundHeight,
            },
            audio: true,
          };

          if (!videoInboundHeight) {
            videoStatsDelayTickRef.current.set(stream.id(), videoStatsDelayTickRef.current.get(stream.id()) + 1);
          } else {
            // if (transport == VideoProtocol.UDP || (transport == VideoProtocol.TCP && !shouldTryTunnel))
            //   videoStatsDelayTickRef.current.set(stream.id(), videoStatsDelayTickRef.current.get(stream.id()) + 1);
            // else
            videoStatsDelayTickRef.current.set(stream.id(), 0);
          }

          const sessions = window?.CSRTCPlayer.getSessions();
          let sessIds = '';

          sessions?.forEach((n: { id: () => string }) => sessIds = `${sessIds} ${n.id()}`);

          const _session = sessions.find((item: any) => item.id() === sessionIdRef.current);
          const _streams = _session?.getStreams();
          let streamIds = '';

          _streams?.forEach((n: { id: () => string }) => streamIds = `${streamIds} ${n.id()}`);

          const _stream = _session?.getStream(stream.id());

          console.log(`WEBRTC STAT WATCHER, BAD Ticks =  ${videoStatsDelayTickRef.current.get(stream.id())}, sessions : ${sessions.length} [${sessIds} ], streams in current session: ${_session?.getStreams().length} [${streamIds} ], stream with active ID ${stream.id()} is in List = ${_stream != undefined}`);
          console.log('stats = ', JSON.stringify(stats.inboundStream.video));

          if (handleStableConnection && videoStatsDelayTickRef.current.get(stream.id()) === 0) {
            handleStableConnection(transport);
          }

          if (videoStatsDelayTickRef.current.get(stream.id()) >= CSRTC_STATS_DELAY_FREQUENCY_LIMIT) {
            if (stream.status() !== CSRTCVideoPlayerStatus.PENDING) {
              stream.stop();
            } else {
              _session.disconnect();
            }

            if (webrtcVideoTunneling && !shouldTryTunnel) {
              if( transport == VideoProtocol.UDP && ENABLE_DIRECT_TCP_TRANSPORT) {
                videoStatsDelayTickRef.current.set(stream.id(), 0);
                console.log('Switch from UDP Transport to TCP (without tunneling)');
                stream.stop();
                return;
              } else {
                processTunnelOptions(shouldTryTunnel);
                videoStatsDelayTickRef.current.set(stream.id(), 0);
                console.log('webrtcVideoTunneling DETECTED, shold try to reinit with tunneling');
                _session.disconnect();
                processSessionError(session, undefined, false);
                return;
              }
            }

            if (!webrtcVideoTunneling) {
              sendVideoFailedEvent({ reason: CSRTCVideoFailedEventReason.disabledTunneling });
              console.log('VIDEO FAILED EVENT', `reason: ${CSRTCVideoFailedEventReason.disabledTunneling}; videoInbounds: ${videoInboundHeight}`);
            }

            if (webrtcVideoTunneling && shouldTryTunnel) {
              sendVideoFailedEvent({ reason: CSRTCVideoFailedEventReason.videoNotAvailableWithTunneling });
              console.log('VIDEO FAILED EVENT', `reason: ${CSRTCVideoFailedEventReason.videoNotAvailableWithTunneling}; videoInbounds: ${videoInboundHeight}`);
            }
            clearInterval(videoStatsCheckIntervalRef.current.get(stream.id()));
          }

          if (!session.getStream(stream.id())) {
            clearInterval(videoStatsCheckIntervalRef.current.get(stream.id()));
          }
        });
      }, CSRTC_STATS_TRACKING_INTERVAL_MS));

      Object.values(CSRTCVideoPlayerSessionStatus).forEach((status) => {
        session.on(status, () => {
          if (status === CSRTCVideoPlayerSessionStatus.FAILED) {
            if (videoMonitoringEventSending) {
              videoMonitoringEventSending({
                reason: 'video termination',
                terminationTimestamp: Date.now(),
              });
            }
          }
        });
      });

      Object.values(CSRTCVideoPlayerStatus).forEach((status) => {
        stream.on(status, () => {
          onStreamStatusChange(status);

          if (status === CSRTCVideoPlayerStatus.PENDING) {
            const firstLoadTime = Date.now() - startTimeRef.current;

            {
              console.log(`!!! WEBRTC-Stream-Status =======> (Stream ID = ${stream.id()}) `, 'CSRTCVideoPlayerStatus.PENDING - Set Handler');

              const video: any = document.getElementById(stream.id());

              if (!video.hasListeners) {
                video.hasListeners = true;

                if (Browser.isSafariWebRTC()) {
                  setWebkitEventHandlers(video);
                }
              }
            }

            console.log(`!!! WEBRTC-Stream-Status =======> (Stream ID = ${stream.id()}) `, 'CSRTCVideoPlayerStatus.PENDING');

            if (videoMonitoringEventSending) {
              videoMonitoringEventSending({
                reason: 'time to initial connection',
                timeToInitialConnection: `${firstLoadTime}ms`,
              });
            }
          }

          if (status === CSRTCVideoPlayerStatus.PLAYING) {
            const firstLoadTime = Date.now() - startTimeRef.current;

            console.log(`!!! WEBRTC-Stream-Status =======> (Stream ID = ${stream.id()}) `, 'CSRTCVideoPlayerStatus.PLAYING');

            if (videoMonitoringEventSending) {
              videoMonitoringEventSending({
                reason: 'time to start seeing video',
                timeToStartSeeingVideo: `${firstLoadTime}ms`,
              });
            }

            if (bufferingStartRef.current !== null) {
              const bufferingEnd = Date.now();
              const bufferingDuration = bufferingEnd - bufferingStartRef.current;

              bufferingStartRef.current = null;

              if (videoMonitoringEventSending) {
                videoMonitoringEventSending({
                  reason: 'video freeze',
                  freezePeriods: `${bufferingDuration}ms`,
                  freezeTimestamp: Date.now(),
                });
              }
            }
          }

          if (status === CSRTCVideoPlayerStatus.PAUSED
            || status === CSRTCVideoPlayerStatus.STOPPED
            || status === CSRTCVideoPlayerStatus.PLAYBACK_PROBLEM) {
            bufferingStartRef.current = Date.now();
            console.log(`!!! WEBRTC-Stream-Status =======> (Stream ID = ${stream.id()}) `, 'CSRTCVideoPlayerStatus.PAUSE/STOP/PROBLEM');
            processSessionError(session, undefined, status);
          }

          if (status === CSRTCVideoPlayerStatus.FAILED) {
            console.log('!!! WEBRTC-Stream-Status', 'CSRTCVideoPlayerStatus.FAILED');
            processSessionError(session, undefined, status);
          }

          if (videoMonitoringEventSending && quality === 'low') {
            videoMonitoringEventSending({
              reason: 'downgrade',
              downgrades: `${quality} quality`,
            });
          }
        });
      });

      if (videoMonitoringEventSending && transport === VideoProtocol.UDP) {
        videoMonitoringEventSending({
          reason: 'protocol switch',
          protocol: 'UDP',
          url: src,
        });
      } else if (videoMonitoringEventSending && transport === VideoProtocol.TCP && !useTunnel) {
        videoMonitoringEventSending({
          reason: 'protocol switch',
          protocol: 'TCP',
          url: src,
        });
      } else if (videoMonitoringEventSending && webrtcVideoTunneling && useTunnel) {
        videoMonitoringEventSending({
          reason: 'protocol switch',
          protocol: 'STUN',
          url: src,
        });
        processTunnelOptions(shouldTryTunnel);
      }

      let attempt = 0;

      if (ENABLE_PERIODICAL_DISCONNECT) {
        videoTesterIntervalRef.current.set(stream.id(), window.setInterval(() => {
          if (!stream || attempt > PERIODICAL_DISCONNECT_PERIOD) {
            clearInterval(videoTesterIntervalRef.current.get(stream.id()));
          } else {
            let logline = 'WEBRTC Transport = ';

            attempt += 1;

            const tunneling = useTunnel ? 'STUN' : transport;
            const sessions = window?.CSRTCPlayer.getSessions();
            const _session = sessions.find((item: any) => item.id() === sessionIdRef.current);
            const _stream = _session?.getStream(stream.id());

            console.log(`TESTER, sec ${attempt}, transport ${tunneling}, sessions : ${sessions.length}, streams : ${_session?.getStreams().length}, stream with active ID ${stream.id()} is in List = ${_stream != undefined}`);

            if (attempt === PERIODICAL_DISCONNECT_PERIOD) {
              logline = `SWITCH TRANSPORT FROM ${transport}`;
              stream.stop();

              logline = `${attempt}: ${logline} current transport ${transport}, tunnel ${shouldTryTunnel}, close session`;

              console.log('!!!', logline);
              clearInterval(videoTesterIntervalRef.current.get(stream.id()));
            }

            if (!session.getStream(stream.id())) {
              clearInterval(videoTesterIntervalRef.current.get(stream.id()));
            }
          }
        }, 1000));
      }
      console.log(stream.status());
    } catch (error) {
      console.error(error);
    }
  }, [
    rtsp,
    onStreamStatusChange,
    quality,
    webrtcVideoTunneling,
    processSessionError,
    streamName,
    useTunnel,
    handleStableConnection,
    transport,
  ]);

  const handleCreateSession = useCallback(() => {
    try {
      // handleDestroy();
      i = i + 1;
      console.log('WEBRTC-SESSION', `SESSION ${i} CREATE START, Tunnel = ${shouldTryTunnel} !!!`);

      const session = window?.CSRTCPlayer.createSession(defaultSessionOptions);

      console.log(`WEBRTC-SESSION, Session IS = ${session.id()}`, 'SESSION CREATE DONE !!!');
      sessionIdRef.current = session.id();

      Object.values(CSRTCVideoPlayerSessionStatus).forEach((status) => {
        session.on(status, () => {
          onSessionStatusChange(status);
        });
      });

      session.on(CSRTCVideoPlayerSessionStatus.ESTABLISHED, (establishedSession: any) => {
        const newSessionId = establishedSession.id();

        handlePlayStream(establishedSession);
        onSessionStatusChange(CSRTCVideoPlayerSessionStatus.ESTABLISHED);
      });
    } catch (error) {
      console.error(error);
    }
  }, [src, handlePlayStream, onSessionStatusChange, sessionIdRef]);

  const handleCleanupDOM = useCallback(() => {
    if (videoContainerRef.current) {
      videoContainerRef.current.innerHTML = '';
    }
  }, []);

  const handleDestroy = useCallback(() => {
    destroy = true;
    cleanSessions();

    const session = getActiveSession();

    handleCleanupDOM();

    if (session) {
      session?.disconnect();
      console.log('DISCONNECT SESSION !!!!!!');
    }
  }, [getActiveSession, handleCleanupDOM]);

  useEffect(() => {
    try {
      window.CSRTCPlayer.init(initOptions || defaultInitOptions);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const shouldReInit = (previousURL !== src) || (previousTryTunnel !== shouldTryTunnel);
    const isPlayerPreCheckSuccess = Browser.isSafariWebRTC() || window?.CSRTCPlayer.getMediaProviders()[0] === MediaProvider.MSE;

    console.log('WEBRTC-STREAM useE0', `- ${previousURL !== src}/${rtsp}/${webrtcVideoTunneling}`);

    if (shouldReInit) {
      // handleDestroy();

      if (src && rtsp) {
        console.log('!!! WEBRTC-STREAM', 'Create new Session');

        if (isPlayerPreCheckSuccess) {
          window?.CSRTCPlayer.playFirstVideo(videoContainerRef.current, false, preloaderUrl).then(() => {
            console.log('!!! WEBRTC-STREAM', 'handleCreateSession playFirstVideo');
            handleCreateSession();
          }).catch((error: any) => {
            console.error(error);
          });
        } else {
          console.log('!!! WEBRTC-STREAM', 'handleCreateSession def');
          handleCreateSession();
          // cleanSessions();
        }
      }
    } else if (rtsp !== previousStreamID) {
      console.log('!!! WEBRTC-STREAM', `Create new Stream (old stream ${previousStreamID}, new stream ${rtsp})`);
      streamName.current = rtsp;

      if (streamsRefMap.current.has(previousStreamID)) {
        streamsRefMap.current.get(previousStreamID).stop();
        streamsRefMap.current.delete(previousStreamID);
      } else {
        const session = getActiveSession();

          session?.disconnect();
      }
    }
  }, [src, rtsp, handleCreateSession, handleDestroy, shouldOptionRebuild, previousURL, previousStreamID]);

  useEffect(() => handleDestroy, [handleDestroy]);

  useEffect(() => {
    window.setTimeout(() => {
      const stream = streamsRefMap.current.get(streamName.current);

      if (stream) {
        stream.setVolume(volume);
      }
    }, 500);
  }, [volume, streamName.current]);

  return (
    <div ref={videoContainerRef} className={className} />
  );
});
