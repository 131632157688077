import React, { useCallback } from 'react';
import { IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import { useTranslation } from 'react-i18next';
import { useStylesWithDeviceInfo } from '../../hooks/useStylesWithDeviceInfo';
import { getStyles, ISwitchStyleProps, ISwitchStyles } from './styles/Switch';

export interface ISwitchProps extends Partial<ISwitchStyleProps> {
  styles?: IStyleFunctionOrObject<ISwitchStyleProps, ISwitchStyles>;
  withLabel?: boolean;
  onChange: (value: boolean) => void;
  e2eSelector?: string;
  openSoundCallback?: () => void;
  additionalIcons?: React.ReactElement;
  language?: string;
}

const SwitchBase: React.FC<ISwitchProps> = ({
  styles,
  className,
  value,
  withLabel = true,
  onChange,
  e2eSelector = 'switch',
  openSoundCallback,
  additionalIcons,
  language,
}) => {
  const { t } = useTranslation();
  const labelOn = t('Switch.On');
  const labelOff = t('Switch.Off');

  const currentLabel = value ? labelOn : labelOff;
  const widestLabel = labelOn.length > labelOff.length ? labelOn : labelOff;

  const handleClick = useCallback(() => {
    openSoundCallback?.();
    onChange(!value);
  }, [value, onChange, openSoundCallback]);

  const classNames = useStylesWithDeviceInfo<ISwitchStyles, ISwitchStyleProps>(styles, {
    language,
    className,
    value,
  });

  return (
    <div
      className={classNames.root}
      role="switch"
      tabIndex={0}
      aria-checked={value}
      data-e2e-selector={e2eSelector}
      onClick={handleClick}
    >
      {withLabel && (
        <>
          { additionalIcons }
          <label className={classNames.label}>{currentLabel}</label>
          <label className={classNames.labelSpacer}>{widestLabel}</label>
        </>
      )}
    </div>
  );
};

export const Switch = React.memo(
  styled<
    ISwitchProps,
    ISwitchStyleProps,
    ISwitchStyles
  >(
    SwitchBase,
    getStyles,
  ),
);
