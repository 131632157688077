import { getKeys } from '../../utils/objectUtils';
import { FADE_DURATION } from './constants';
import { SoundChannel } from './soundChannel';

export abstract class SoundsControllerModel<T extends Record<string, string>,
  Channel extends SoundChannel = SoundChannel,
  Category extends keyof T = keyof T,
> {
  protected maps: { [K in Category]: Record<T[K], Channel> } = {} as any;

  preloadResources(): Promise<void>[] {
    return this.getAllChannels()
      .map((channel) => (
        new Promise<void>((resolve) => {
          channel.once('load', () => resolve());
          channel.once('loaderror', () => {
            // we don't want app to fail even if sound is not preloaded
            resolve();
          });
        })
      ));
  }

  setOverallVolume(volume: number): void {
    this?.getAllChannels()
      .forEach((channel) => channel.volume(volume));
  }

  setOverallVolumeExceptType(type: Category, volume: number): void {
    this.getAllChannelsExceptType(type)
      .forEach((channel) => channel.volume(volume));
  }

  getSoundChannelsByType(type: Category): Channel[] {
    return Object.values(this.getMapByType(type) ?? {});
  }

  protected register<C extends Category>(type: C, map: Record<T[C], Channel>): void {
    this.maps[type] = map;
  }

  protected replaceSound<C extends Category>(type: C, name: T[C], channel: Channel): void {
    this.maps[type][name] = channel;
  }

  /**
   *
   * @param type - type of channel
   * @param name - name of channel
   * @return {Promise<boolean>}- resolves to true sound is ended, to false if sound not found
   */
  protected play<C extends Category>(type: C, name: T[C]): Promise<boolean> {
    const channel = this.maps[type][name];

    if (channel) {
      return this.playChannel(channel).then(() => true);
    }

    return Promise.resolve(false);
  }

  protected stop<C extends Category>(type: C, name: T[C]): void {
    const channel = this.maps[type][name];

    if (channel) {
      channel.stop();
    }
  }

  protected playSoundWithoutInterruption<C extends Category>(type: C, name: T[C]): void {
    const soundToPlay = this.getMapByType(type)[name];

    if (soundToPlay) {
      const channelsList = this.getSoundChannelsByType(type);

      this.stopChannels(channelsList.filter((bgCh: Channel) => bgCh !== soundToPlay));

      if (soundToPlay instanceof SoundChannel) {
        soundToPlay.setLoop(true);
      }
      this.playChannelWithFade(soundToPlay);
    }
  }

  protected stopChannelsByType(type: Category): void {
    this.stopChannels(this.getSoundChannelsByType(type));
  }

  protected setVolumeByCategory(category: Category, volume: number): void {
    this.getSoundChannelsByType(category)
      .forEach((channel) => channel.volume(volume));
  }

  protected getMapByType<C extends Category>(type: C): Record<T[C], Channel> {
    return this.maps[type];
  }

  /**
   *
   * @param channel - channel to play
   * @returns Promise - resolves when sound is ended
   */
  protected playChannel = (channel: Channel): Promise<void> => {
    channel.play();

    return new Promise((resolve) => {
      channel.once('end', () => resolve());
    });
  };

  protected playChannelWithFade = (channel: Channel) => {
    if (!channel.playing()) {
      channel.fade(0, channel.volume(), FADE_DURATION);
      channel.play();
    }
  };

  protected stopChannels = (group: Channel[]) => {
    group.forEach((channel) => {
      if (channel.playing()) {
        channel.fade(channel.volume(), 0, FADE_DURATION);
        // TODO: We need regulation between fade in and fade out after first priority tasks
        channel.once('fade', () => {
          channel.stop();
        });
      }
    });
  };

  protected getAllChannels(): Channel[] {
    return getKeys(this.maps)
      .reduce((acc: Channel[], key) => [...acc, ...this.getSoundChannelsByType(key)], []);
  }

  protected getAllChannelsExceptType(type: Category): Channel[] {
    return getKeys(this.maps).reduce((acc: Channel[], key) => {
      if (key !== type) {
        return [...acc, ...this.getSoundChannelsByType(key)];
      }

      return [...acc];
    }, []);
  }
}
