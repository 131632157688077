import {
  IGetTransactionsRequestFilterParams,
  IGetTransactionsResponseBody,
  ITransactionsDismissRequestBody,
  ITransactionsRetryRequestBody,
  ITransactionsResendDWHDataRequestBody,
} from './types';
import { getUrl, makeRequest } from '../../services/api';

export const getTransactionsRequest = (filterParams: IGetTransactionsRequestFilterParams) => {
  const url = getUrl('/transaction', filterParams);

  return makeRequest<IGetTransactionsResponseBody>(url);
};

export const retryTransactionsRequest = (data: ITransactionsRetryRequestBody) => {
  const url = getUrl('/transaction/retry');

  return makeRequest(url, data, 'POST');
};

export const dismissTransactionsRequest = (data: ITransactionsDismissRequestBody) => {
  const url = getUrl('/transaction/dismiss');

  return makeRequest(url, data, 'POST');
};

export const resendDWHDataTransactionsRequest = (data: ITransactionsResendDWHDataRequestBody) => {
  const url = getUrl('/transaction/resendDWHData');

  return makeRequest(url, data, 'POST');
};
