import { hexToRGBA } from './helpers';

export const Color = {
  white: '#ffffff',
  black: '#000000',
  lightBlack: '#222222',
  border: '#181818',
  menuHeader: '#eccb9a',
  primaryText: '#ffffff',
  goldText: '#eccb9a',
  popupBackground: '#333333',
  popupBorder: '#555555',
  buttonConfirm: '#0AC43F',
  buttonConfirmPressed: '#1DE155',
  buttonCancel: '#757575',
  buttonCancelPressed: '#9B9B9B',
  green: '#32A81D',
  gray: '#adadad',
  queueTitle: '#9e63c2',
  queueIcon: '#81679c',
  queueBtn: '#adadad',
  popupTextColor: 'rgba(2, 66, 16, 0.91)',

  gameFooter: {
    text: '#473755',
    value: '#9546c2',
    valueChristmas: '#ed2b20',
    bg: '#080213',
    totalWinBg: '#230845',
    totalWinBgChristmas: '#710000',
  },
  currencyValueColor: {
    value: '#ffbf24',
    valueStroke: '#560301',
  },
  bonusWheelPopup: {
    border: '#b167df',
    text: '#a682c5',
    background: 'rgba(32, 11, 45, 0.8)',
  },
  gameContainer: {
    background: '#6d597a',
  },
  queueCounter: {
    background: '#200b2d',
    border: '#32164d',
    text: '#ffffff',
    divider: '#ffffff',
    bgChristmas: '#75411c',
  },
  lobby: {
    background: '#393d74',
    dividerBackground: '#6f5d68',
    progressBarShadow: 'rgba(217, 255, 176, 0.4)',
    progressGradient1: '#87ef00',
    progressGradient2: '#17a400',
    progressGradient3: '#2e8d00',
    progressGradient4: '#08b200',
    progressGradient5: 'rgba(53, 255, 58, 0.6)',
  },
  card: {
    border: '#86a5fc',
    defaultBackground: '#5020b0',
  },
  groupColor: {
    darkBlue: '#3d5ddc',
  },
  overlay: {
    primaryBackground: '#0e1438',
    secondaryBackground: '#04001f',
  },
  loadingOverlay: {
    spinnerBackground: hexToRGBA('#1f4969', 0.65),
  },
  progressBar: {
    main: '#f0c22e',
  },
  infoDialog: {
    border: '#7430D6',
    startBackgroundGradient: '#310A4A',
    endBackgroundGradient: '#280941',
  },
  notFound: {
    background: '#474749',
    text: '#b0b2ac',
    icon: '#d8d8d8',
    link: '#3d9bcc',
  },
  scrollBar: {
    scrollThumbOutline: '#120b23',
    scrollThumbBorder: '#6f5d8c',
    scrollTrackBackground: '#120b23',
  },
  panel: {
    backgroundGradient1: '#2d095a',
    backgroundGradient2: '#14032b',
    headerIconFill: '#fff',
  },
  switch: {
    label: '#757575',
    border: '#0d1014',
    btnGradient1: '#f2f2f2',
    btnGradient2: '#f9f9f9',
    btnGradient3: '#e3dae3',
    bodyBackground: '#f2628b',
    boxShadow: '#523d68',
  },
  chipWin: {
    background: '#cfdeff',
    color: '#2a275a',
    border: '#2a275a',
    loader: '#2a275a',
    loadingText: '#7af7f3',
    bonus: '#fff8ea',
    gray: '#c9c9c9',
    bonusGame: '#2b1e04',
    youWon: '#e5c56a',
    nextTimeText: '#ffffff',
    betterLuck: '#7af7f3',
  },
  chipsBar: {
    border: '#0a001d',
    background: '#27004c',
  },
  queueBar: {
    background: '#27004c',
    border: '#0a001d',
    valueStroke: '#0a001d',
    separator: '#8489f8',
  },
  liveStreamBadge: {
    path2: '#010101',
    path3: '#fff',
    path4: '#e73445',
  },
  starSpinner: {
    star: '#cf28f0',
    circlesBackground: '#cf28f0',
    boxShadow: '#18ffff',
  },
  controlPanel: {
    backgroundGradient: 'linear-gradient(to bottom, #542A73 0, #542A73 1.887%, #200641 1.887%, #090215 82.47%, #070213 82.47%, #070213)',
  },
  timeoutProgressBar: {
    rootShadow: 'rgba(188, 51, 251, 0.73)',
    text: '#fff',
    stroke: '#552e72',
    backgroundProgressBar: '#0e0816',
    red: '#e10019',
    yellow: '#ffc600',
    green: '#2ad873',
    gradient1: '#331e38',
    gradient2: '#1a081f',
    gradient3: '#160019',
    gradient4: '#5d00ad',
  },
  mainButton: {
    glowColor: '#a4dca2',
    buyButtonTextWrap: 'rgba(0, 0, 0, 0.6)',
    buyButtonTextWrapShadow: 'rgba(255, 255, 255, 0.21)',
  },
  fortuneWheel: {
    text: '#adadad',
    cardListShadow: '#72538d',
    fortuneWheelCardListBorder: '#6f3194',
    bgContainer: '#1E0F2A',
    activeBgContainer: '#391F56',
    blinkedTriangle1: '#64395B',
    blinkedTriangle2: '#6C7BC5',
    skipButtonText: '#9DEC20',
  },
  almostWinDialog: {
    amountBackgroundGradient1: '#FBED8D',
    amountBackgroundGradient2: '#DC9F1B',
    amountBackgroundGradient3: '#D49D1B',
    amountBackgroundGradient4: '#F1B52E',
    amountDropShadow: '#680e02',
    coinTextShadow: '#9dec20',
    coinTextColor: 'rgba(3, 84, 14, 0.91)',
    buyMoreTextShadow: 'rgba(0, 0, 0, 0.4)',
    boxShadow: hexToRGBA('#FFFFFF', 0.12),
    border: hexToRGBA('#2F1D8B', 0.96),
    backgroundColor: '#3b0a65',
    divider: '#8613e4',
    btnContentTextShadow: '#9dec20',
    btnContentColor: hexToRGBA('#024210', 0.91),
    btnContentDisabledColor: hexToRGBA('#1E1E1E', 0.91),
  },
  tutorialDialog: {
    bgGradient1: '#070e38',
    bgGradient2: '#26379d',
    continueBtn: '#ffea37',
  },
  dialog: {
    borderColor: '#a700fe',
    backgroundGradientStartColor: 'rgba(62,41,170,1)',
    backgroundGradientStopColor: 'rgba(49,4,90,1)',
    boxShadow: hexToRGBA('#000000', 0.8),
    textShadow: '#3b0a65',
    titleGradientColor1: 'rgb(255, 255, 255)',
    titleGradientColor2: 'rgb(201, 201, 201)',
    titleGradientColor3: 'rgb(171, 97, 229)',
  },
};

export enum FontSize {
  Size1 = '8.5rem',
  Size2 = '7.5rem',
  Size3 = '6.4rem',
  Size4 = '5.7rem',
  Size5 = '5.41rem',
  Size6 = '5rem',
  Size7 = '4.2rem',
  Size8 = '3.7rem',
  Size9 = '3.3rem',
  Size10 = '3rem',
  Size11 = '2.8rem',
  Size12 = '2.1rem',
  Size13 = '1.524rem',
  Size14 = '18rem',
  Size15 = '16rem',
  Size16 = '4rem',
  Size17 = '14rem',
  Size18 = '15rem',
  Size19 = '2.7rem',
  Size20 = '11rem',
  Size21 = '2.6rem',
  Size22 = '13rem',
  Size23 = '12rem',
  Size24 = '10rem',
  Size25 = '9rem',
  Size26 = '4.6rem',
  Size27 = '16rem',
}

export const Time = {
  defaultAnimationTime: 0.15,
  spinnerAnimationTime: 2,
  winAnimationDuration: 2,
  progressBarBlink: 1,
};

export enum FontFamily {
  primary = 'Roboto',
  OpenSansBold = 'OpenSans-Bold',
  Arial = 'Arial',
  MontserratBold = 'Montserrat-Bold',
  MontserratRegular = 'Montserrat-Regular',
  MontserratSemiBold = 'Montserrat-SemiBold',
  ArcadiaMCPlay = 'Arcadia-MC-Play',
}

export const TextShadow = '0 0.03em 0.03em rgba(0, 0, 0, 0.41)';

export const CommonViewWidth = 112;

export const DeviceWidth = {
  mobileMax: 736,
};

export enum ClawUIStyle {
  MCRegular = 'mc_regular',
  MCChristmas = 'mc_christmas',
}

export const DialogSize = {
  lg: 'large',
  sm: 'small',
};
