export const formatCurrency = (
  value: number,
  options: Intl.NumberFormatOptions & { currency: string },
  showCurrencySymbol?: boolean,
  minDigits?: number,
) => {
  const {
    minimumFractionDigits = minDigits ?? 0,
    maximumFractionDigits = minDigits ?? 2,
    currency,
    currencyDisplay = 'symbol',
    ...restOptions
  } = options || {};

  if (!currency) {
    console.error('Currency is required but was not provided.');

    return `${value.toFixed(minimumFractionDigits)} UNKNOWN`;
  }

  const rawCurrency = currency.toLowerCase();
  const isMultiplier = rawCurrency === 'x';
  const normalizedCurrency = isMultiplier ? 'x' : rawCurrency.toUpperCase();
  const isValidStandardCurrency = /^[A-Z]{3}$/.test(normalizedCurrency);

  const cryptoCurrencies = [
    "ADA", "DOG", "FTN", "BC2", "BN2", "ET2", "LT2", "TRX", "TET", "XB2", "XB3", "PTX", "XRP"
  ];

  const isCrypto = cryptoCurrencies.includes(normalizedCurrency);

  let maxFractionDigits = isCrypto ? 4 : 2;

  let formattedValue: string;

  if (isValidStandardCurrency) {
    try {
      const formatter = new Intl.NumberFormat('en-US', {
        style: showCurrencySymbol ? 'currency' : 'decimal',
        currency: normalizedCurrency,
        currencyDisplay,
        minimumFractionDigits,
        maximumFractionDigits: maxFractionDigits,
        ...restOptions,
      });

      return formatter.format(value);
    } catch (error) {
      console.error(`Failed to format currency value: ${value}`, error);

      return `${value} ${normalizedCurrency}`;
    }
  }
   else {
    formattedValue = Number(value).toFixed(4).toString();
  }

  if (formattedValue.includes('.')) {
    formattedValue = formattedValue.replace(/0+$/, '');
    if (formattedValue.endsWith('.')) {
      formattedValue = formattedValue.slice(0, -1);
    }
  }

  if (showCurrencySymbol) {
    return isCrypto ? `${normalizedCurrency} ${formattedValue}` : `${normalizedCurrency}${formattedValue}`;
  }

  return `${formattedValue} ${normalizedCurrency}`;
};