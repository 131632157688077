import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import { HLSVideoPlayerStatus, VideoStreamQuality } from './types';

window.HELP_IMPROVE_VIDEOJS = false;

interface IHLSVideoPlayerProps {
  className?: string;
  src: string;
  volume: number;
  onStatusChange?: (status: HLSVideoPlayerStatus) => void;
  videoMonitoringEventSending?: (data: any) => void;
  quality?: VideoStreamQuality;
}

const VIDEO_TYPE = 'application/vnd.apple.mpegurl';

export const HLSVideoPlayer: React.FC<IHLSVideoPlayerProps> = React.memo(({
  className = '',
  src,
  volume,
  onStatusChange = () => {},
  videoMonitoringEventSending,
  quality,
}) => {
  const refVideoContainer = useRef<HTMLDivElement>(null);
  const refPlayer = useRef<Player | null>(null);
  const startTimeRef = useRef(Date.now());
  const bufferingStartRef = useRef(null as number | null);

  useEffect(() => {
    const videoNode = document.createElement('video');

    videoNode.setAttribute('type', VIDEO_TYPE);
    // We need to set "muted" and "playsInline" to support autoplay in iOS Safari
    // https://webkit.org/blog/7734/auto-play-policy-changes-for-macos
    videoNode.setAttribute('muted', '');
    videoNode.setAttribute('playsInline', '');
    videoNode.setAttribute('autoplay', '');

    const player = videojs(
      videoNode,
      {
        controls: false,
        autoplay: true,
        // We need to muted video to support autoplay in iOS Safari https://webkit.org/blog/7734/auto-play-policy-changes-for-macos
        muted: true,
        fluid: false,
        liveui: false,
        nativeControlsForTouch: false,
        defaultVolume: volume,
        children: [],
      },
      () => {
        refPlayer.current?.addClass(className);
      },
    );

    refPlayer.current = player;

    player.on('loadedmetadata', () => {
      startTimeRef.current = Date.now();
    });

    player.on('play', () => {
      const firstLoadTime = Date.now() - startTimeRef.current;

      if (videoMonitoringEventSending) {
        videoMonitoringEventSending({
          reason: 'time to initial connection',
          timeToInitialConnection: `${firstLoadTime}ms`,
        });
      }
    });

    if (videoMonitoringEventSending && quality === 'low') {
      videoMonitoringEventSending({
        reason: 'downgrade',
        downgrades: `${quality} quality`,
      });
    }

    player.on('playing', () => {
      const firstLoadTime = Date.now() - startTimeRef.current;

      if (videoMonitoringEventSending) {
        videoMonitoringEventSending({
          reason: 'time to start seeing video',
          timeToStartSeeingVideo: `${firstLoadTime}ms`,
        });
      }

      if (bufferingStartRef.current !== null) {
        const bufferingEnd = Date.now();
        const bufferingDuration = bufferingEnd - bufferingStartRef.current;

        bufferingStartRef.current = null;

        if (videoMonitoringEventSending) {
          videoMonitoringEventSending({
            reason: 'video freeze',
            freezePeriods: `${bufferingDuration}ms`,
            freezeTimestamp: Date.now(),
          });
        }
      }
    });

    player.on('waiting', () => {
      bufferingStartRef.current = Date.now();
    });

    player.on('stalled', () => {
      bufferingStartRef.current = Date.now();
    });

    player.on('ended', () => {
      if (videoMonitoringEventSending) {
        videoMonitoringEventSending({
          reason: 'video termination',
          terminationTimestamp: Date.now(),
        });
      }
    });

    const handleError = () => {
      if (videoMonitoringEventSending) {
        videoMonitoringEventSending({
          reason: 're-connect',
          reconnectTimestamp: Date.now(),
        });
      }
    };

    player.on('error', handleError);

    Object.values(HLSVideoPlayerStatus).forEach((status) => {
      refPlayer.current?.on(status, () => {
        onStatusChange(status);
      });
    });

    console.log('HLSVideoPlayer link', src);

    refPlayer.current?.src({
      src,
      type: VIDEO_TYPE,
    });

    refPlayer.current?.ready(() => {
      refPlayer.current?.play()
        ?.then(() => {
          refPlayer.current?.muted(true);
        })
        ?.catch((error) => {
          console.warn('The HLSPlayer play request was interrupted. Trying again…', error);
        });
    });

    refVideoContainer.current?.appendChild(videoNode);

    return () => {
      player.off('error', handleError);
      refPlayer.current?.dispose();
    };
  }, [src, refVideoContainer.current, onStatusChange, className]);

  useEffect(() => {
    refPlayer.current?.volume(volume);
  }, [refPlayer.current, volume]);

  return (
    <div ref={refVideoContainer} className={className} />
  );
});
