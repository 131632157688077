import { IRawStyle, IStyle } from '@fluentui/merge-styles';
import { IDeviceInfoContext } from '../../../types/general';
import { FontFamily } from '../../../styles/constants';

export interface IButtonStylesProps extends IDeviceInfoContext {
  className?: string;
  classNameImg?: string;
  classNameContent?: string;
  isDisabled?: boolean;
  isPressed?: boolean;
  isWithPressedAsset?: boolean;
  isWithDisabledAsset?: boolean;
}

export interface IButtonStyles {
  root: IStyle;
  normalImg: IStyle;
  pressedImg: IStyle;
  disabledImg: IStyle;
  content: IStyle;
}

const baseStateImgStyles: IRawStyle = {
  display: 'block',
  position: 'absolute',
  width: '100%',
  left: 0,
  top: 0,
  opacity: 0,
};

export const getStyles = ({
  className, classNameContent, classNameImg, isPressed, isDisabled, isWithPressedAsset, isWithDisabledAsset, calculateRemValue,
}: IButtonStylesProps): IButtonStyles => ({
  root: [
    {
      position: 'relative',
      display: 'inline-block',
      width: '100%',
      appearance: 'none',
      background: 'none',
      border: 'none',
      outline: 'none',
      userSelect: 'none',
      padding: 0,
      cursor: 'pointer',
      touchAction: 'pan-y', // AS-1882 - IOS Double click native zoom fix
      '-webkit-tap-highlight-color': 'transparent',
    },
    isPressed && !isWithPressedAsset && {
      filter: 'contrast(125%) brightness(103%)',
    },
    isDisabled && !isWithDisabledAsset && {
      filter: 'grayscale(100%)',
      pointerEvents: 'none',
    },
    isDisabled && {
      filter: 'grayscale(100%)',
      pointerEvents: 'none',
    },
    className,
  ],
  normalImg: [
    {
      display: 'block',
      width: '100%',
    },
    isPressed && isWithPressedAsset && {
      opacity: 0,
    },
    classNameImg,
  ],
  pressedImg: [
    baseStateImgStyles,
    isPressed && {
      opacity: 1,
    },
    classNameImg,
  ],
  disabledImg: [
    baseStateImgStyles,
    isDisabled && {
      opacity: 1,
    },
    classNameImg,
  ],
  content: [
    {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      padding: `${calculateRemValue(1.7)} ${calculateRemValue(2.5)} ${calculateRemValue(4)}`,
      fontFamily: FontFamily.MontserratBold,
      textTransform: 'uppercase',
      letterSpacing: '1px',
    },
    classNameContent,
  ],
});
