import { IRawStyle, IStyle } from '@fluentui/merge-styles';
import { IDeviceInfoContext } from '../../../types/general';
import { Color, FontSize, Time } from '../../../styles/constants';

export interface ISwitchStyleProps extends IDeviceInfoContext {
  className?: string;
  value?: boolean;
  language?: string;
}

export interface ISwitchStyles {
  root: IStyle;
  label: IStyle;
  labelSpacer: IStyle;
}

export const getStyles = ({
  className, value, calculateRemValue,
}: ISwitchStyleProps): ISwitchStyles => {
  const generalLabelStyles: IRawStyle = {
    width: '100%',
    color: Color.white,
    fontSize: calculateRemValue(FontSize.Size8),
    fontWeight: '700',
    textTransform: 'uppercase',
    pointerEvents: 'none',
    padding: `0 ${calculateRemValue(1.5)} 0 ${calculateRemValue(7.5)}`,
  };

  return {
    root: [
      {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        minWidth: calculateRemValue(17.15),
        height: calculateRemValue(7.79),
        borderRadius: calculateRemValue(4.21),
        backgroundColor: Color.switch.bodyBackgroundColor,
        border: `${calculateRemValue(0.5)} solid ${Color.switch.borderColor}`,
        cursor: 'pointer',
        selectors: {
          ':after': {
            position: 'absolute',
            content: '""',
            width: calculateRemValue(5.5),
            height: calculateRemValue(5.5),
            top: calculateRemValue(0.6),
            left: calculateRemValue(0.8),
            zIndex: 1,
            borderRadius: '50%',
            backgroundImage: `
            linear-gradient(135deg, ${Color.switch.barBackgroundGradientStartColor} 0%, ${Color.switch.barBackgroundGradientEndColor} 100%)
            `,
            transition: `left ${Time.defaultAnimationTime}s linear`,
          },
        },
      },
      value && {
        selectors: {
          ':after': {
            left: `calc(100% - ${calculateRemValue(6.2)})`,
          },
        },
      },
      className,
    ],
    labelSpacer: [
      generalLabelStyles,
      {
        opacity: 0,
      },
    ],
    label: [
      generalLabelStyles,
      {
        position: 'absolute',
      },
      value && {
        padding: `0 ${calculateRemValue(7.5)} 0 ${calculateRemValue(1.5)}`,
      },
    ],
  };
};
