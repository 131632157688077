import { IStyle } from '@fluentui/merge-styles';
import { FontSize } from 'arcadia-claw/src/styles/constants';
import { Color } from '../../../styles/constants';
import { IDeviceInfoContext } from '../../../types';
import iconDivider from '../assets/divider.png';

export interface IAccordionStyleProps extends IDeviceInfoContext {
  className?: string;
  isExpanded: boolean;
  isClawGame?: boolean;
}

export interface IAccordionStyles {
  accordion: IStyle;
  header: IStyle;
  icon: IStyle;
  children: IStyle;
}

export const getStyles = ({
  className, calculateRemValue, isExpanded, isClawGame,
}: IAccordionStyleProps): IAccordionStyles => ({
  accordion: [
    isClawGame ? {
      position: 'relative',
      marginTop: '0px',
      cursor: 'pointer',
      '-webkit-tap-highlight-color': 'transparent',
      padding: `${calculateRemValue(5)} ${calculateRemValue(12)} ${calculateRemValue(2)} ${calculateRemValue(5)}`,
      selectors: {
        '&:after': {
          content: '""',
          position: 'absolute',
          backgroundImage: `url(${iconDivider})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          bottom: '-4px',
          left: calculateRemValue(4),
          right: calculateRemValue(13),
          height: '2rem',
        },
        '&:first-child:before': {
          content: '""',
          position: 'absolute',
          backgroundImage: `url(${iconDivider})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          top: '-4px',
          left: calculateRemValue(4),
          right: calculateRemValue(13),
          height: '2rem',
        },
      },
    }
      : {
        textTransform: 'uppercase',
        borderTop: `2px solid ${Color.border}`,
        overflow: 'hidden',
        selectors: {
          ':last-child': {
            borderBottom: `2px solid ${Color.border}`,
          },
        },
      },
    className,
  ],
  header: [
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      fontSize: calculateRemValue(FontSize.Size7),
      padding: `${calculateRemValue(4)} 0`,
    },
    isClawGame && {
      padding: 0,
    },
    isExpanded && {
      color: Color.menuHeader,
    },
  ],
  icon: [
    {
      stroke: 'currentColor',
      transform: 'rotate(-90deg)',
      transition: 'transform 0.8s',
      width: calculateRemValue(3),
      maxWidth: calculateRemValue(3),
      flexShrink: 0,
    },
    isExpanded && {
      transform: 'rotate(0)',
    },
  ],
  children: [
    {
      borderTop: `2px solid ${Color.border}`,
      transformOrigin: 'top center',
    },
    isClawGame && {
      maxWidth: '52rem',
      marginTop: '4rem',
    },
  ],
});
