import { Howl, HowlOptions } from 'howler';

export interface ISoundChannel extends Howl {
  name: string;
}

export class SoundChannel extends Howl implements ISoundChannel {
  constructor(public name: string, src: string[], options?: HowlOptions) {
    super({
      src,
      volume: 0,
      ...options,
    });
  }
  setLoop(loop: boolean): void {
    this.loop(loop);
  }
}
