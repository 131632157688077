const languages = {
  enUS: 'en-US',
  zhCN: 'zh-CN',
  jaJP: 'ja-JP',
  ruRU: 'ru-RU',
  ukUA: 'uk-UA',
  esES: 'es-ES',
  trTR: 'tr-TR',
  svSE: 'sv-SE',
  itIT: 'it-IT',
  daDK: 'da-DK',
  ptPT: 'pt-PT',
  deDE: 'de-DE',
  nlNL: 'nl-NL',
  hiIN: 'hi-IN',
  roRO: 'ro-RO'
};

const languagesWithTextStrokeSupport = [
  languages.enUS,
  languages.ruRU,
  languages.ukUA,
  languages.esES,
  languages.trTR,
  languages.svSE,
  languages.itIT,
  languages.daDK,
  languages.ptPT,
  languages.deDE,
  languages.nlNL,
  languages.hiIN,
  languages.roRO
];

const namespaces = {
  general: 'general',
};

const lng = languages.enUS;
const ns = Object.values(namespaces);
const defaultNS = namespaces.general;
const nsSeparator = '/';

module.exports = {
  languages,
  namespaces,
  lng,
  ns,
  defaultNS,
  nsSeparator,
  languagesWithTextStrokeSupport,
};
