import { createAction } from '@reduxjs/toolkit';
import {
  IGetMachinesRequestFiltersParams,
  IGetMachinesResponseBody,
  IMachineDialogActionState,
  IMachineActionRequestBody,
  MachineAction,
  IPostMachineRequestBody,
  IPutMachineRequestBody,
  IMachineDialogFormState,
  IMachineDialogActivateState,
  IActivateRequestBody,
  IMachineDialogReassignState,
  IMachineReassignRequestBody,
  IGetMachineDispensersRequestBody,
  IGetMachineLogsRequestBody,
  IGetMachineLogsResponseBody,
  IExportMachineLogsRequestBody,
  IMachineLogsDialog,
  IMachineCommentsDialog,
  IGetMachineCommentsRequestBody,
  IGetMachineCommentsResponseBody,
  IAddMachineCommentRequestBody,
  IAddMachineCommentsSuccessPayload
} from '../types';

export interface IExecuteActionPayload extends IMachineActionRequestBody {
  action: MachineAction;
}

export const postMachine = createAction<IPostMachineRequestBody>('MACHINES/POST_MACHINE');

export const getMachines = createAction<IGetMachinesRequestFiltersParams>('MACHINES/GET_MACHINES');
export const getMachinesSuccess = createAction<IGetMachinesResponseBody>('MACHINES/GET_MACHINES/SUCCESS');
export const getMachinesError = createAction('MACHINES/GET_MACHINES/ERROR');

export const putMachine = createAction<IPutMachineRequestBody>('MACHINES/PUT_MACHINE');

export const setMachinesDialogForm = createAction<Partial<IMachineDialogFormState> | undefined>('MACHINES/SET_DIALOG_FORM');
export const mergeMachinesDialogForm = createAction<Partial<IMachineDialogFormState> | undefined>('MACHINES/MERGE_DIALOG_FORM');

export const mergeMachinesDialogAction = createAction<Partial<IMachineDialogActionState> | undefined>('MACHINES/MERGE_DIALOG_ACTION');
export const executeMachinesAction = createAction<IExecuteActionPayload>('MACHINES/EXECUTE_ACTION');

export const setDialogActivateMachine = createAction<Partial<IMachineDialogActivateState> | undefined>('MACHINES/SET_ACTIVATE_DIALOG');
export const mergeDialogActivateMachine = createAction<Partial<IMachineDialogActivateState> | undefined>('MACHINES/MERGE_ACTIVATE_DIALOG');
export const activateMachine = createAction<IActivateRequestBody>('MACHINES/ACTIVATE_MACHINE');

export const setDialogReassignMachine = createAction<Partial<IMachineDialogReassignState> | undefined>('MACHINES/SET_REASSIGN_DIALOG');
export const mergeDialogReassignMachine = createAction<Partial<IMachineDialogReassignState> | undefined>('MACHINES/MERGE_REASSIGN_DIALOG');
export const reassignMachine = createAction<IMachineReassignRequestBody>('MACHINES/REASSIGN');

export const exportMachines = createAction<IGetMachinesRequestFiltersParams>('MACHINES/EXPORT');
export const exportMachinesSuccess = createAction('MACHINES/EXPORT/SUCCESS');
export const exportMachinesError = createAction('MACHINES/EXPORT/ERROR');

export const getMachineDispensers = createAction<IGetMachineDispensersRequestBody>('MACHINES/GET_MACHINE_DISPENSERS');

export const setMachineLogsDialog = createAction<Partial<IMachineLogsDialog> | undefined>('MACHINES/SET_MACHINE_LOGS_DIALOG');
export const mergeMachineLogsDialog = createAction<Partial<IMachineLogsDialog> | undefined>('MACHINES/MERGE_MACHINE_LOGS_DIALOG');

export const getMachineLogs = createAction<IGetMachineLogsRequestBody>('MACHINES/GET_MACHINE_LOGS');
export const getMachineLogsSuccess = createAction<IGetMachineLogsResponseBody>('MACHINES/GET_MACHINE_LOGS/SUCCESS');
export const getMachineLogsError = createAction('MACHINES/GET_MACHINE_LOGS/ERROR');

export const exportMachineLogs = createAction<IExportMachineLogsRequestBody>('MACHINES/EXPORT_MACHINE_LOGS');
export const exportMachineLogsSuccess = createAction('MACHINES/EXPORT_MACHINE_LOGS/SUCCESS');
export const exportMachineLogsError = createAction('MACHINES/EXPORT_MACHINE_LOGS/ERROR');

export const setMachineCommentsDialog = createAction<Partial<IMachineCommentsDialog> | undefined>('MACHINES/SET_MACHINE_COMMENTS_DIALOG');

export const getMachineComments = createAction<IGetMachineCommentsRequestBody>('MACHINES/GET_MACHINE_COMMENTS');
export const getMachineCommentsSuccess = createAction<IGetMachineCommentsResponseBody>('MACHINES/GET_MACHINE_COMMENTS/SUCCESS');
export const getMachineCommentsError = createAction('MACHINES/GET_MACHINE_COMMENTS/ERROR');

export const addMachineComments = createAction<IAddMachineCommentRequestBody>('MACHINES/ADD_MACHINE_COMMENT');
export const addMachineCommentsSuccess = createAction<IAddMachineCommentsSuccessPayload>('MACHINES/ADD_MACHINE_COMMENT/SUCCESS');
export const addMachineCommentsError = createAction('MACHINES/ADD_MACHINE_COMMENT/ERROR');
