import i18next from 'i18next';
import { languagesWithTextStrokeSupport } from '../../i18n/constants';
import scrollIcon from '../assets/images/scrollIcon.png';

export const hexToRGB = (hex: string) => {
  let r = '0';
  let g = '0';
  let b = '0';

  if (hex.length === 4) {
    r = `0x${hex[1]}${hex[1]}`;
    g = `0x${hex[2]}${hex[2]}`;
    b = `0x${hex[3]}${hex[3]}`;
  } else if (hex.length === 7) {
    r = `0x${hex[1]}${hex[2]}`;
    g = `0x${hex[3]}${hex[4]}`;
    b = `0x${hex[5]}${hex[6]}`;
  }

  return { r: +r, g: +g, b: +b };
};

export const hexToRGBA = (hex: string, alpha = 1) => {
  const { r, g, b } = hexToRGB(hex);

  return `rgba(${+r},${+g},${+b},${alpha})`;
};

// https://en.wikipedia.org/wiki/HSL_and_HSV#From_RGB
export const hexToHsl = (
  hex: string,
  {
    h: hOffset = 0,
    s: sOffset = 0,
    l: lOffset = 0,
  }: { h?: number; s?: number; l?: number } = {},
): string => {
  let { r, g, b } = hexToRGB(hex);

  // r, g, b in [0, 1]
  r /= 255;
  g /= 255;
  b /= 255;

  const xMax = Math.max(r, g, b);
  const xMin = Math.min(r, g, b);

  const v = xMax;
  const delta = xMax - xMin;

  let h: number;

  if (!delta) {
    h = 0;
  } else if (v === r) {
    h = (g - b) / delta;
  } else if (v === g) {
    h = 2 + (b - r) / delta;
  } else {
    h = 4 + (r - g) / delta;
  }

  const h1 = 60 * h < 0 ? 60 * h + 360 : 60 * h;
  const l = Math.round(((xMax + xMin) / 2) * 100);
  const s = delta === 0 ? 0 : Math.round((100 * delta) / Math.abs(2 * v - delta));

  return `hsl(${h1 + hOffset}, ${s + sOffset}%, ${l + lOffset}%)`;
};

export const forceMinAspectRatio = (width: number, height: number) => ({
  selectors: {
    '&:before': {
      content: '""',
      float: 'left',
      height: 0,
      paddingTop: `${((height / width) * 100)}%`,
    },
    '&:after': {
      content: '""',
      display: 'table',
      clear: 'both',
    },
  },
});

export const textWithStroke = (strokeSize: string, strokeColor: string, forceForAllLanguages?: boolean) => {
  const isWithStroke = forceForAllLanguages || languagesWithTextStrokeSupport.includes(i18next.language);

  return (
    isWithStroke && {
      paintOrder: 'stroke fill',
      '-webkit-text-stroke': `${strokeSize} ${strokeColor}`,
    }
  );
};

export const fixArtifactPixels = () => ({
  transform: 'translate3d(0, 0, 0) scale(0.999999)',
  '-webkit-transform': 'translate3d(0, 0, 0) scale(0.999999)',
});

export const textBackgroundGradient = (gradient: string, textfillColor = 'transparent') => (
  gradient && {
    ...fixArtifactPixels(),
    display: 'inline-block',
    backgroundImage: gradient,
    '-webkit-text-fill-color': textfillColor,
    backgroundClip: 'text',
    '-webkit-background-clip': 'text',
  }
);

export const scrollbar = (width: string, colorThumbOutline: string, colorThumbBorder: string, colorTrackBackground: string) => ({
  '* ::-webkit-scrollbar': {
    width,
  },
  '* ::-webkit-scrollbar-button': {
    display: 'none',
  },
  '* ::-webkit-scrollbar-thumb::before': {
    width,
  },
  '* ::-webkit-scrollbar-thumb::after': {
    backgroundImage: `url(${scrollIcon})`,
  },
  '* ::-webkit-scrollbar-thumb': {
    width,
    outline: `2px solid ${colorThumbOutline}`,
    border: `1px solid ${colorThumbBorder}`,
    borderRadius: 10,
    backgroundImage: `url(${scrollIcon}), linear-gradient(90deg, rgba(55,40,94,1) 0%, rgba(14,8,29,1) 100%)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  '* ::-webkit-scrollbar-track': {
    backgroundColor: colorTrackBackground,
    padding: 2,
    borderRadius: 10,
  },
});
