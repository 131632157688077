import { createReducer } from '@reduxjs/toolkit';
import { ITEMS_PER_PAGE } from 'arcadia-common-fe';
import { IMachinesReducer, MachineAction } from '../types';
import {
  exportMachines,
  exportMachinesError,
  exportMachinesSuccess,
  getMachines,
  getMachinesError,
  getMachinesSuccess,
  mergeMachinesDialogAction,
  setMachinesDialogForm,
  mergeMachinesDialogForm,
  mergeDialogActivateMachine,
  setDialogActivateMachine,
  setDialogReassignMachine,
  mergeDialogReassignMachine,
  getMachineLogs,
  getMachineLogsSuccess,
  getMachineLogsError,
  setMachineLogsDialog,
  mergeMachineLogsDialog,
  setMachineCommentsDialog,
  getMachineComments,
  getMachineCommentsSuccess,
  addMachineComments,
  addMachineCommentsSuccess,
  addMachineCommentsError
} from './actions';

export const initialState: IMachinesReducer = {
  isLoading: false,
  isExporting: false,
  total: 0,
  machines: [],
  dialogAction: {
    id: null,
    action: MachineAction.dry,
    isOpen: false,
    isLoading: false,
  },
  dialogForm: {
    isOpen: false,
    isLoading: false,
  },
  dialogActivate: {
    id: null,
    isOpen: false,
    isLoading: false,
    gameId: null,
  },
  dialogReassign: {
    id: null,
    isOpen: false,
    isLoading: false,
  },
  dialogLogs: {
    machine: null,
    isOpen: false,
    isLoading: false,
    isExporting: false,
    total: 0,
    logs: [],
    filterParams: {
      take: ITEMS_PER_PAGE,
    },
  },
  dialogComments: {
    machine: null,
    isOpen: false,
    isLoading: false,
    isExporting: false,
    total: 0,
    comments: [],
    filterParams: {
      take: ITEMS_PER_PAGE,
    },
  },
};

export const machinesReducer = createReducer(initialState, (builder) => {
  builder.addCase(getMachines, (state) => ({
    ...state,
    total: 0,
    machines: [],
    isLoading: true,
  }));
  builder.addCase(getMachinesSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
  builder.addCase(getMachinesError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(setMachinesDialogForm, (state, { payload }) => ({
    ...state,
    dialogForm: {
      ...initialState.dialogForm,
      ...payload,
    },
  }));
  builder.addCase(mergeMachinesDialogForm, (state, { payload }) => ({
    ...state,
    dialogForm: {
      ...state.dialogForm,
      ...payload,
    },
  }));
  builder.addCase(mergeMachinesDialogAction, (state, { payload }) => ({
    ...state,
    dialogAction: {
      ...state.dialogAction,
      ...payload,
    },
  }));

  builder.addCase(setDialogActivateMachine, (state, { payload }) => ({
    ...state,
    dialogActivate: {
      ...initialState.dialogActivate,
      ...payload,
    },
  }));
  builder.addCase(mergeDialogActivateMachine, (state, { payload }) => ({
    ...state,
    dialogActivate: {
      ...state.dialogActivate,
      ...payload,
    },
  }));

  builder.addCase(setDialogReassignMachine, (state, { payload }) => ({
    ...state,
    dialogReassign: {
      ...initialState.dialogReassign,
      ...payload,
    },
  }));
  builder.addCase(mergeDialogReassignMachine, (state, { payload }) => ({
    ...state,
    dialogReassign: {
      ...state.dialogReassign,
      ...payload,
    },
  }));

  builder.addCase(exportMachines, (state) => ({
    ...state,
    isExporting: true,
  }));
  builder.addCase(exportMachinesSuccess, (state) => ({
    ...state,
    isExporting: false,
  }));
  builder.addCase(exportMachinesError, (state) => ({
    ...state,
    isExporting: false,
  }));

  builder.addCase(getMachineLogs, (state, { payload }) => ({
    ...state,
    dialogLogs: {
      ...state.dialogLogs,
      ...payload,
      isOpen: true,
      isLoading: true,
    },
  }));
  builder.addCase(getMachineLogsSuccess, (state, { payload }) => ({
    ...state,
    dialogLogs: {
      ...state.dialogLogs,
      ...payload,
      isLoading: false,
    },
  }));
  builder.addCase(getMachineLogsError, (state) => ({
    ...state,
    dialogLogs: {
      ...state.dialogLogs,
      isLoading: false,
    },
  }));

  builder.addCase(getMachineComments, (state, { payload }) => ({
    ...state,
    dialogComments: {
      ...state.dialogComments,
      ...payload,
      isOpen: true,
      isLoading: true,
    },
  }));
  builder.addCase(setMachineCommentsDialog, (state, { payload }) => ({
    ...state,
    dialogComments: {
      ...initialState.dialogComments,
      ...payload,
    },
  }));
  builder.addCase(getMachineCommentsSuccess, (state, { payload }) => ({
    ...state,
    dialogComments: {
      ...state.dialogComments,
      ...payload,
      comments: payload.records,
      total: payload.total,
      isLoading: false,
    },
  }));
  builder.addCase(addMachineComments, (state) => ({
    ...state,
    dialogComments: {
      ...state.dialogComments,
      isLoading: true,
    },
  }));

  builder.addCase(addMachineCommentsSuccess, (state, { payload }) => ({
    ...state,
    dialogComments: {
      ...state.dialogComments,
      isLoading: false,
      comments: [
        {
          id: payload.commentResponse.id,
          comment: payload.commentResponse.comment,
          createdAt: payload.commentResponse.createdAt,
          username: payload.commentResponse.username,
        },
        ...(state.dialogComments.comments || []),
      ],
    },
  }));
  builder.addCase(addMachineCommentsError, (state) => ({
    ...state,
    dialogComments: {
      ...state.dialogComments,
      isLoading: false,
    },
  }));
  builder.addCase(setMachineLogsDialog, (state, { payload }) => ({
    ...state,
    dialogLogs: {
      ...initialState.dialogLogs,
      ...payload,
    },
  }));
  builder.addCase(mergeMachineLogsDialog, (state, { payload }) => ({
    ...state,
    dialogLogs: {
      ...state.dialogLogs,
      ...payload,
    },
  }));
});
