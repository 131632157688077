import { OperatorId } from './operators';
import { GroupId } from './groups';

export enum BrandStatus {
  disabled = 'disabled',
  enabled = 'enabled'
}

export enum BrandAction {
  enable,
  disable,
  remove,
}
export type BrandId = number;

export interface ILinkToGroup {
  groupId: GroupId;
  isHidden: boolean;
  isPrivate: boolean;
  transactionFee: number | null;
  priority: number | null;
  spilloverThreshold: number | null;
}

export interface IBrand {
  status: BrandStatus;
  id: BrandId;
  name: string;
  regulation: {};
  configuration: {};
  externalId: string;
  secondExternalId: string;
  embedderUrls: string;
  operatorId: OperatorId;
  operatorName: string;
  brandSecretsId: number | null;
  linkToGroups: ILinkToGroup[];
  apiConnectorId: string;
}

export type IBrands = IBrand[];
